import React, { useCallback, useState } from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Seo from "../components/seo";
import withPreview from "../utility/with-preview";
import { GatsbyImage, getImage, withArtDirection } from "gatsby-plugin-image";
import { useJsApiLoader, GoogleMap, Marker } from "@react-google-maps/api";
import Icon from "../components/elements/icon-svg";
import pointer_active from "../images/Pointer_single_active.svg";
import { PrevArrow, NextArrow } from "../components/elements/arrows";
import Slider from "react-slick";
import classNames from "classnames";
import CardLayout from "../components/fasce/card-layout";
import FasciaIconeTesto from "../components/fasce/fascia-icone-testo";
import { useCentriAssistenzaDataLayer } from "../utility/dataLayerUtils";

const libraries = ["geometry", "places"];

const mapOptions = {
  maxZoom: 19,
  zoom: 13,
  disableDefaultUI: true,
};

const PaginaDettaglioTemplate = ({ data, location }) => {
  const date = new Date();
  const [googleMapsGeocoder, setGoogleMapsGeocoder] = useState({});
  const [map, setMap] = useState(null);
  const [centriAssistenzaDataLayerPush] = useCentriAssistenzaDataLayer();

  const { imageXl, imageL, imageTablet, imageMobile, logo, text, plusServices, infoAggiuntive } =
    data.page;

  let seoH1 = `Centro assistenza ${infoAggiuntive.nome} ${infoAggiuntive.localita}`;  
  let seoTitle = `Centro assistenza ${infoAggiuntive.nome} ${infoAggiuntive.localita}: clima e caldaie`;
  let seoDescription = `Contatta il centro assistenza ${infoAggiuntive.nome} ${infoAggiuntive.localita} di ENGIE e scopri tutti i servizi: manutenzione e sostituzione climatizzatori, scaldabagno e caldaie. Chiama ora.`;
  

  if (infoAggiuntive.localita == 'Ancona') {
    seoH1 = `Centro assistenza Ancona`;
    seoTitle = `Centro assistenza Ancona: clima e caldaie`;
    seoDescription = `Contatta il centro assistenza Ancona di ENGIE e scopri tutti i servizi: manutenzione e sostituzione climatizzatori, scaldabagno e caldaie. Chiama ora.`;
  }

  var settings = {
    infinite: infoAggiuntive.brand.length <= 4 ? false : true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: true,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          infinite: infoAggiuntive.brand.length <= 3 ? false : true,
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          infinite: infoAggiuntive.brand.length <= 2 ? false : true,
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 576,
        settings: {
          infinite: infoAggiuntive.brand.length <= 1 ? false : true,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.GATSBY_GOOGLE_MAPS_API_KEY,
    libraries: libraries,
  });

  const initMaps = useCallback(function onLoad(map) {
    setMap(map);
    const gmObj = window.google.maps;
    if (!gmObj) return;
    const gmGeocoder = new gmObj.Geocoder();
    setGoogleMapsGeocoder(gmGeocoder);
  }, []);

  const geolocate = () => {
    return new Promise((resolve, reject) => {
      if (window && "geolocation" in window.navigator) {
        navigator.geolocation.getCurrentPosition(function (position) {
          const { latitude, longitude } = position.coords;
          const latlng = new window.google.maps.LatLng(latitude, longitude);
          if (googleMapsGeocoder) {
            googleMapsGeocoder.geocode({ location: latlng }, function (results, status) {
              if (status === window.google.maps.GeocoderStatus.OK) {
                resolve(results);
              } else {
                reject("Geocode error: " + status);
              }
            });
          }
        }, reject);
      } else {
        reject("Your browser doesn't support geolocation.");
      }
    });
  };

  const getPosition = async () => {
    try {
      const results = await geolocate();
      window.open(
        `https://www.google.com/maps?saddr=${results[0].formatted_address}&daddr=${infoAggiuntive.nome} ${infoAggiuntive.indirizzo} ${infoAggiuntive.cap} ${infoAggiuntive.localita} ${infoAggiuntive.provincia}`,
        "_blank",
        "noreferrer"
      );
    } catch (err) {
      console.error("Geolocation error: ", err);
      window.open(
        `https://www.google.com/maps?daddr=${infoAggiuntive.nome} ${infoAggiuntive.indirizzo} ${infoAggiuntive.cap} ${infoAggiuntive.localita} ${infoAggiuntive.provincia}`,
        "_blank",
        "noreferrer"
      );
    }
  };

  const headerImg =
    (imageXl?.node || imageL?.node) &&
    withArtDirection(
      getImage(imageXl?.node || imageL?.node),
      [
        imageMobile?.node && {
          media: "(max-width: 768px)",
          image: getImage(imageMobile.node),
        },
        imageTablet?.node && {
          media: "(max-width: 992px)",
          image: getImage(imageTablet.node),
        },
        imageL?.node && {
          media: "(max-width: 1200px)",
          image: getImage(imageL.node),
        },
      ].filter(Boolean)
    );

  const loghi = {
    BERETTA: data.beretta,
    BOSCH: data.bosch,
    "HERMANN SAUNIER DUVAL": data.hermann,
    MITSUBISHI: data.mitsubishi,
    VAILLANT: data.vaillant,
    ARISTON: data.ariston,
    JUNKERS: data.junkers,
    RIELLO: data.riello,
    CHAFFOTEAUX: data.chaffoteaux,
    FERROLI: data.ferroli,
    BONGIOANNI: data.bongioanni,
    BAXI: data.baxi,
    IMMERGAS: data.immergas,
    ECOFLAM: data.ecoflam,
  };

  return (
    <>
      {isLoaded && (
        <Layout location={location}>
          <Seo
            title={`${seoTitle}`}
            description={`${seoDescription}`}
            path={`${location.pathname}`}
          />
          <div data-template="pagina-dettaglio">
            <section className="hub-dettaglio__header container">
              {headerImg && (
                <div className="hub-dettaglio__image">
                  <GatsbyImage image={headerImg} alt={""} />
                </div>
              )}
              <div
                className={classNames("hub-dettaglio__info", {
                  "hub-dettaglio__info--without-header-image": headerImg === undefined,
                })}
              >
                {logo && (
                  <div className="hub-dettaglio__logo">
                    <GatsbyImage
                      image={getImage(logo?.node)}
                      alt={logo?.value?.description || ""}
                    />
                  </div>
                )}
                {infoAggiuntive?.nome && (
                  <h1 className="hub-dettaglio__name">{seoH1}</h1>
                )}
                {infoAggiuntive?.tipologia && (
                  <div className="hub-dettaglio__info-name">
                    <span className="hub-dettaglio__store-type">
                      {infoAggiuntive.tipologia === "CAT"
                        ? "Società del gruppo ENGIE"
                        : "Partner ENGIE"}
                    </span>
                    <Icon
                      name={infoAggiuntive.tipologia === "CAT" ? "engie-group" : "engie-partner"}
                      cls={
                        infoAggiuntive.tipologia === "CAT"
                          ? "hub-dettaglio__engie-group"
                          : "hub-dettaglio__engie-partner"
                      }
                    />
                  </div>
                )}
                {(infoAggiuntive?.indirizzo ||
                  infoAggiuntive?.cap ||
                  infoAggiuntive?.localita ||
                  infoAggiuntive?.provincia) && (
                  <address className="hub-dettaglio__address-header">
                    <span>
                      {infoAggiuntive?.indirizzo ? infoAggiuntive.indirizzo : ""}
                      {infoAggiuntive?.cap ? ", " + infoAggiuntive.cap : ""}
                    </span>
                    <span>
                      {infoAggiuntive?.localita ? infoAggiuntive.localita : ""}
                      {infoAggiuntive?.provincia ? " (" + infoAggiuntive.provincia + ")" : ""}
                    </span>
                  </address>
                )}
                <div className="hub-dettaglio__actions">
                  {infoAggiuntive?.telefono && (
                    <a
                      href={`tel:${infoAggiuntive.telefono}`}
                      className="cta-link"
                      onClick={() =>
                        centriAssistenzaDataLayerPush(
                          "Chiama",
                          "Pagina dettaglio",
                          infoAggiuntive?.nome
                        )
                      }
                    >
                      <span className="hub-dettaglio__phone-icon">
                        <Icon name="smartphone" />
                      </span>
                      <span>CHIAMA</span>
                    </a>
                  )}
                  <button
                    className="hub-dettaglio__geolocation"
                    onClick={() => {
                      getPosition();
                      centriAssistenzaDataLayerPush(
                        "Indicazioni",
                        "Pagina dettaglio",
                        infoAggiuntive?.nome
                      );
                    }}
                  >
                    <span className="hub-dettaglio__map-icon">
                      <Icon name="map-marker-alt" />
                    </span>
                    <span>INDICAZIONI</span>
                  </button>
                  {infoAggiuntive?.whatsapp && (
                    <a
                      href={`https://wa.me/39${infoAggiuntive.whatsapp}`}
                      target="_blank"
                      rel="noreferrer"
                      className="hub-dettaglio__whatsapp"
                      onClick={() =>
                        centriAssistenzaDataLayerPush(
                          "Chatta",
                          "Pagina dettaglio",
                          infoAggiuntive?.nome
                        )
                      }
                    >
                      <span className="hub-dettaglio__whatsapp-icon">
                        <Icon name="whatsapp" />
                      </span>
                      <span>CHATTA</span>
                    </a>
                  )}
                </div>
                <div className="hub-dettaglio__authorized-center">
                  {infoAggiuntive?.centroAutorizzato?.filter((brand) => brand !== "").length >
                    0 && (
                    <>
                      <h3 className="hub-dettaglio__authorized-center-header">
                        Centro autorizzato
                      </h3>
                      <div className="hub-dettaglio__brand-container">
                        {infoAggiuntive?.centroAutorizzato
                          ?.filter((brand) => brand !== "")
                          .map((brand, key) => (
                            <GatsbyImage image={getImage(loghi[brand])} alt={brand} key={key} />
                          ))}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </section>
            {text?.value && (
              <section className="hub-dettaglio__text-container container">
                <div dangerouslySetInnerHTML={{ __html: text.value }}></div>
              </section>
            )}
            <CardLayout
              data={{
                ...data.dataCard,
                card: data.dataCard.card?.filter((item) =>
                  infoAggiuntive.servizi.includes(item?.content?.cardKey?.value)
                ),
              }}
              isDetail={true}
            />
            {plusServices?.value?.filter((service) => service !== "").length > 0 && (
              <FasciaIconeTesto
                data={{
                  ...data.dataFasciaIconeTesto,
                  element: data.dataFasciaIconeTesto.element?.filter((item) =>
                    plusServices.value.includes(item?.content?.key?.value)
                  ),
                }}
              />
            )}
            {infoAggiuntive?.brand && (
              <section className="hub-dettaglio__slider-container container">
                <h2 className="hub-dettaglio__slider-title">Marchi trattati</h2>
                <Slider {...settings}>
                  {infoAggiuntive.brand
                    .filter((item) => !!loghi[item])
                    .map((item, key) => (
                      <GatsbyImage image={getImage(loghi[item])} alt={item} key={key} />
                    ))}
                </Slider>
              </section>
            )}
            <section className="hub-dettaglio__columns-container container">
              <div className="hub-dettaglio__column">
                <h3 className="hub-dettaglio__column-header">Indirizzo</h3>
                <div className="hub-dettaglio__column-body">
                  {(infoAggiuntive?.indirizzo ||
                    infoAggiuntive?.cap ||
                    infoAggiuntive?.localita ||
                    infoAggiuntive?.provincia) && (
                    <address className="hub-dettaglio__address">
                      <span>
                        {infoAggiuntive?.indirizzo ? infoAggiuntive.indirizzo : ""}
                        {infoAggiuntive?.cap ? ", " + infoAggiuntive.cap : ""}
                      </span>
                      <span>
                        {infoAggiuntive?.localita ? infoAggiuntive.localita : ""}
                        {infoAggiuntive?.provincia ? " (" + infoAggiuntive.provincia + ")" : ""}
                      </span>
                    </address>
                  )}
                  {infoAggiuntive?.telefono && <span>{infoAggiuntive.telefono}</span>}
                </div>
              </div>
              {infoAggiuntive?.orari && (
                <div className="hub-dettaglio__column">
                  <h3 className="hub-dettaglio__column-header">Orari di apertura:</h3>
                  <div className="hub-dettaglio__time-table">
                    <ul>
                      {infoAggiuntive.orari.map((item, key) => (
                        <li
                          key={key}
                          className={classNames("hub-dettaglio__table-item", {
                            "hub-dettaglio__table-item--active": date.getDay() === key + 1,
                          })}
                        >
                          <span>{item?.giorno}</span>
                          <span>{item?.orario}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              )}
            </section>
            <section className="hub-dettaglio__map-container">
              <GoogleMap
                mapContainerClassName="hub-dettaglio__map"
                options={mapOptions}
                onLoad={initMaps}
                center={{ lat: infoAggiuntive.lat, lng: infoAggiuntive.lon }}
              >
                <Marker
                  map={map}
                  title={infoAggiuntive.nome}
                  position={new window.google.maps.LatLng(infoAggiuntive.lat, infoAggiuntive.lon)}
                  icon={{
                    url: pointer_active,
                  }}
                  onClick={() => {
                    getPosition();
                  }}
                />
              </GoogleMap>
            </section>
          </div>
        </Layout>
      )}
    </>
  );
};

export default withPreview(PaginaDettaglioTemplate, {
  subField: "page",
  fixed: true,
});

export const query = graphql`
  query ($id: String) {
    page: liferayJskLayoutPaginaDettaglio(id: { eq: $id }) {
      liferayFields {
        siteId
        articleId
      }
      imageXl {
        node {
          gatsbyImageData(layout: FULL_WIDTH)
        }
        value {
          description
        }
      }
      imageL {
        node {
          gatsbyImageData(layout: FULL_WIDTH)
        }
        value {
          description
        }
      }
      imageTablet {
        node {
          gatsbyImageData(layout: FULL_WIDTH)
        }
        value {
          description
        }
      }
      imageMobile {
        node {
          gatsbyImageData(layout: FULL_WIDTH)
        }
        value {
          description
        }
      }
      logo {
        node {
          gatsbyImageData
        }
        value {
          description
        }
      }
      text {
        value
      }
      plusServices {
        value
      }
      infoAggiuntive {
        nome
        tipologia
        localita
        provincia
        cap
        indirizzo
        lat
        lon
        servizi
        telefono
        whatsapp
        brand
        centroAutorizzato
        orari {
          giorno
          orario
        }
      }
    }
    dataCard: liferayJskCardLayout(liferayFields: { title: { eq: "Servizi offerti" } }) {
      ...JskCardLayoutFragment
    }
    dataFasciaIconeTesto: liferayJskFasciaIconeTesto(
      liferayFields: { title: { eq: "Servizi aggiuntivi" } }
    ) {
      ...JskFasciaIconeTestoFragment
    }
    beretta: file(relativePath: { eq: "brand-images/beretta.png" }) {
      childImageSharp {
        gatsbyImageData(height: 92, layout: FIXED)
      }
    }
    bosch: file(relativePath: { eq: "brand-images/bosch.png" }) {
      childImageSharp {
        gatsbyImageData(height: 92, layout: FIXED)
      }
    }
    hermann: file(relativePath: { eq: "brand-images/hermann.png" }) {
      childImageSharp {
        gatsbyImageData(height: 92, layout: FIXED)
      }
    }
    mitsubishi: file(relativePath: { eq: "brand-images/mitsubishi.png" }) {
      childImageSharp {
        gatsbyImageData(height: 92, layout: FIXED)
      }
    }
    vaillant: file(relativePath: { eq: "brand-images/vaillant.png" }) {
      childImageSharp {
        gatsbyImageData(height: 92, layout: FIXED)
      }
    }
    ariston: file(relativePath: { eq: "brand-images/ariston.png" }) {
      childImageSharp {
        gatsbyImageData(height: 92, layout: FIXED)
      }
    }
    junkers: file(relativePath: { eq: "brand-images/junkers.png" }) {
      childImageSharp {
        gatsbyImageData(height: 92, layout: FIXED)
      }
    }
    riello: file(relativePath: { eq: "brand-images/riello.png" }) {
      childImageSharp {
        gatsbyImageData(height: 92, layout: FIXED)
      }
    }
    chaffoteaux: file(relativePath: { eq: "brand-images/chaffoteaux.png" }) {
      childImageSharp {
        gatsbyImageData(height: 92, layout: FIXED)
      }
    }
    ferroli: file(relativePath: { eq: "brand-images/ferroli.png" }) {
      childImageSharp {
        gatsbyImageData(height: 92, layout: FIXED)
      }
    }
    bongioanni: file(relativePath: { eq: "brand-images/bongioanni.png" }) {
      childImageSharp {
        gatsbyImageData(height: 92, layout: FIXED)
      }
    }
    baxi: file(relativePath: { eq: "brand-images/baxi.png" }) {
      childImageSharp {
        gatsbyImageData(height: 92, layout: FIXED)
      }
    }
    immergas: file(relativePath: { eq: "brand-images/immergas.png" }) {
      childImageSharp {
        gatsbyImageData(height: 92, layout: FIXED)
      }
    }
    ecoflam: file(relativePath: { eq: "brand-images/ecoflam.png" }) {
      childImageSharp {
        gatsbyImageData(height: 92, layout: FIXED)
      }
    }
    viessmann: file(relativePath: { eq: "brand-images/viessmann.png" }) {
      childImageSharp {
        gatsbyImageData(height: 92, layout: FIXED)
      }
    }
  }
`;
